var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getAuthInstance } from '../../firebase';
import { getIDTokenFromServiceWorkerOrFirebase } from '../../store/slices/auth';
import { getSaasBackendEndpoint, httpRequest, httpRequestWithToken } from '../../utils/http';
export const fetchCustomToken = () => __awaiter(void 0, void 0, void 0, function* () {
    const currentUser = getAuthInstance().currentUser;
    if (!currentUser)
        return '';
    const url = `${getSaasBackendEndpoint()}/api/v1/users/me/customToken`;
    const idToken = yield getIDTokenFromServiceWorkerOrFirebase();
    const res = yield httpRequestWithToken({
        url,
        method: 'POST',
        payload: { idToken },
    });
    const { data } = res;
    return data.customToken || '';
});
const getCustomTokenURL = (newUser = false) => __awaiter(void 0, void 0, void 0, function* () {
    const firebaseCustomToken = yield fetchCustomToken();
    let link = `ava.me://open/web?firebaseCustomToken=${firebaseCustomToken}`;
    if (newUser) {
        link += '&newUser=true';
    }
    return link;
});
const createOrganization = ({ type, name, role, placeId, }) => __awaiter(void 0, void 0, void 0, function* () {
    return httpRequestWithToken({
        url: `${getSaasBackendEndpoint()}/api/v1/organization`,
        method: 'POST',
        payload: {
            type,
            name,
            role,
            placeId,
        },
    });
});
const getOrCreateUser = (data) => __awaiter(void 0, void 0, void 0, function* () {
    // NOTE: if email is not verified this will throw 403
    return httpRequestWithToken({
        url: `${getSaasBackendEndpoint()}/api/v1/users/me`,
        method: 'PATCH',
        payload: data,
    });
});
const getUser = (language) => __awaiter(void 0, void 0, void 0, function* () {
    return httpRequestWithToken({
        url: `${getSaasBackendEndpoint()}/api/v1/users/me?language=${language}`,
        method: 'GET',
    });
});
const createLicence = ({ licence, language, trial }) => __awaiter(void 0, void 0, void 0, function* () {
    const payload = Object.assign(Object.assign({}, licence), { language,
        trial });
    if (licence.type === 'magicLink') {
        return httpRequest({
            url: `${getSaasBackendEndpoint()}/api/v1/licences/magicLink`,
            method: 'POST',
            payload,
        });
    }
    else {
        return httpRequestWithToken({
            url: `${getSaasBackendEndpoint()}/api/v1/licences`,
            method: 'POST',
            payload,
        });
    }
});
const deleteLicence = ({ id, licence, language }) => httpRequestWithToken({
    url: `${getSaasBackendEndpoint()}/api/v1/licences/${id}`,
    method: 'DELETE',
    payload: Object.assign(Object.assign({}, licence), { language }),
});
const updateLicence = ({ id, licence, recipient, language, }) => __awaiter(void 0, void 0, void 0, function* () {
    return httpRequestWithToken({
        url: `${getSaasBackendEndpoint()}/api/v1/licences/${id}`,
        method: 'PATCH',
        payload: Object.assign(Object.assign({}, licence), { recipient,
            language }),
    });
});
export const getStripePlan = ({ id, language }) => __awaiter(void 0, void 0, void 0, function* () {
    return httpRequestWithToken({
        url: `${getSaasBackendEndpoint()}/api/v1/stripe/plan/${id}?language=${language}`,
        method: 'GET',
    });
});
export const users = {
    fetchCustomToken,
    getCustomTokenURL,
    getOrCreateUser,
    getUser,
};
export const organizations = {
    createOrganization,
};
export const licences = {
    createLicence,
    deleteLicence,
    updateLicence,
};
