var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import 'firebase/analytics';
import 'firebase/remote-config';
import firebase from 'firebase/app';
export { getAuthInstance, setAuthInstance } from './base';
export const analytics = firebase.analytics();
export const remoteConfig = firebase.remoteConfig();
remoteConfig.settings.minimumFetchIntervalMillis = 60 * 60 * 1000;
export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const facebookProvider = new firebase.auth.FacebookAuthProvider();
export const appleProvider = new firebase.auth.OAuthProvider('apple.com');
let firestore = undefined;
export const getFirestore = () => __awaiter(void 0, void 0, void 0, function* () {
    yield import(/* webpackChunkName: "firebase" */ 'firebase/firestore');
    if (!firestore)
        firestore = firebase.firestore();
    return firestore;
});
export const setFirestore = (instance) => {
    firestore = instance;
};
export default firebase;
export const restName = 'firebase-mobile';
