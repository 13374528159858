/* eslint-disable unused-imports/no-unused-imports */
import { makeStyles } from '@mui/styles';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { createBrowserHistory } from 'history';
import React, { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter, Navigate, Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import { AppWrapper } from '../App';
import Loading from '../components/Loading';
import OnboardingV2 from '../Layouts/OnboardingV2';
import Muter from '../modules/AudioV2/Muter';
import { CCV2ConversationPageSwitch } from '../modules/ccV2/CCV2ConversationPageSwitch';
import { CONVOV2_APP_LAUNCH_ROUTE, CONVOV2_HOME_PAGE_ROUTE } from '../modules/convoV2/constants';
import { ConvoV2ConversationPageSwitch } from '../modules/convoV2/ConvoV2ConversationPageSwitch';
import { LoginListener } from '../modules/login/LoginListener';
import GlobalHotkeyHandler from '../modules/scribe/GlobalHotkeyHandler';
import { selectAccountUser } from '../selectors/account';
import { selectIsTextToSpeechV2 } from '../selectors/auth';
import { selectSpeechLang } from '../selectors/legacy-conversation';
import { selectV1TtsVoices } from '../selectors/textToSpeech';
import { selectAvaId } from '../selectors/userProfile';
import { v1FindBestTTSVoice } from '../store/slices/textToSpeech';
import { useAppDispatch, useAppSelector } from '../store/store';
import { Z_INDICES } from '../styles';
import { logoutAndClean } from '../utils/user';
import PrivateRouteWrapper from './PrivateRouteWrapper';
// The Default component contains the header, which is not always loaded, and if it is
// it contains a lot of components related to Ava-Connect and others.
const Default = lazy(() => import(/* webpackChunkName: "default" */ '../Layouts/Default'));
// Components relating to starting the web app through various means. They are very often
// loaded without any other component.
const BranchRedirectScreen = lazy(() => import(/* webpackChunkName: "branch" */ '../components/BranchRedirectScreen'));
const AppDownloadScreen = lazy(() => import(/* webpackChunkName: "appDownload" */ '../components/AppDownloadScreen'));
const DesktopLoginSuccess = lazy(() => import(/* webpackChunkName: "desktopLogin" */ '../components/DesktopLoginSuccess'));
const ElectronLandingPage = lazy(() => import(/* webpackChunkName: "electron_landing_page" */ '../modules/onboarding/ElectronLandingPage'));
// Scribing components
const ScribeDashboardContainer = lazy(() => import(/* webpackChunkName: "scribe" */ '../modules/scribe/ScribeDashboard'));
// Conversations component shared between a few different routes.
const ScribeConversationsContainer = lazy(() => import(/* webpackChunkName: "transcripts" */ '../modules/scribe/ScribeConversationsContainer'));
// Account management pages
const Dashboard = lazy(() => import(/* webpackChunkName: "account" */ '../modules/account/pages/dashboard'));
const LicenceBuy = lazy(() => import(/* webpackChunkName: "account" */ '../modules/account/pages/license/buy'));
const Payment = lazy(() => import(/* webpackChunkName: "account" */ '../modules/account/pages/license/payment'));
const PaymentSucceed = lazy(() => import(/* webpackChunkName: "account" */ '../modules/account/pages/payment-succeed'));
const OrganizationCreate = lazy(() => import(/* webpackChunkName: "account" */ '../modules/account/pages/organization/create'));
const AccountStart = lazy(() => import(/* webpackChunkName: "account" */ '../modules/account/pages/start'));
// Currently unused Workspace V2 component
const Workspace = lazy(() => import(/* webpackChunkName: "workspace" */ '../components/Workspace'));
// Onboarding components (used only during onboarding)
const ScribeLoginProfiling = lazy(() => import(/* webpackChunkName: "loginProfiling" */ '../modules/scribe/ScribeLoginProfilingContainer'));
const Onboarding = lazy(() => import(/* webpackChunkName: "onboarding" */ '../modules/onboarding/Onboarding'));
// Account settings components
const Boost = lazy(() => import(/* webpackChunkName: "account-settings" */ '../modules/account-settings/boost/Boost'));
const General = lazy(() => import(/* webpackChunkName: "account-settings" */ '../modules/account-settings/General'));
const MyProfile = lazy(() => import(/* webpackChunkName: "account-settings" */ '../modules/account-settings/MyProfile'));
const Navigation = lazy(() => import(/* webpackChunkName: "account-settings" */ '../modules/account-settings/Navigation'));
// ConvoV1 components
const ConversationPage = lazy(() => import(/* webpackChunkName: "transcript" */ '../views/conversation/ConversationPage/ConversationPage'));
// ConvoV2 components
const ConvoV2AppLaunchScreen = lazy(() => import(/* webpackChunkName: "convoV2" */ '../modules/convoV2/ConvoV2AppLaunchScreen'));
const ConvoV2ConversationPage = lazy(() => import(/* webpackChunkName: "convoV2" */ '../modules/convoV2/ConvoV2ConversationPage'));
const ConvoV2HomePage = lazy(() => import(/* webpackChunkName: "transcript" */ '../modules/convoV2/ConvoV2HomePage'));
const browserHistory = createBrowserHistory();
Sentry.init({
    integrations: [
        new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV5Instrumentation(browserHistory),
        }),
    ],
    tracesSampleRate: 1.0,
    ignoreErrors: ['connection appears to be offline'],
});
const LogoutComponent = () => {
    logoutAndClean();
    return React.createElement(Navigate, { to: "/web/login", replace: true });
};
const AppRoutes = () => {
    const avaId = useAppSelector(selectAvaId);
    const accountUser = useAppSelector(selectAccountUser);
    const webAppAuthenticate = () => Boolean(avaId);
    const accountAuthenticate = () => Boolean(accountUser === null || accountUser === void 0 ? void 0 : accountUser.id);
    return (React.createElement("div", { id: "gesture" },
        React.createElement(BrowserRouter, null,
            React.createElement(Routes, null,
                React.createElement(Route, { element: React.createElement(RouterWrapper, null) },
                    React.createElement(Route, { element: React.createElement(OnboardingWrapper, null) },
                        React.createElement(Route, { path: "web/onboarding/profile/*", element: React.createElement(ScribeLoginProfiling, null) }),
                        React.createElement(Route, { path: "web/signup", element: React.createElement(Onboarding, null) }),
                        React.createElement(Route, { path: "web/login", element: React.createElement(Onboarding, { changeModeToLogin: true }) })),
                    React.createElement(Route, { element: React.createElement(WebAppWrapper, null) },
                        React.createElement(Route, { path: "/", element: React.createElement(Navigate, { to: "/web", replace: true }) }),
                        React.createElement(Route, { path: "branch/:branchUrlId", element: React.createElement(BranchRedirectScreen, null) }),
                        React.createElement(Route, { path: "download", element: React.createElement(AppDownloadScreen, null) }),
                        React.createElement(Route, { path: "workspace/*", element: React.createElement(Workspace, null) }),
                        React.createElement(Route, { path: "web/login/desktop", element: React.createElement(ElectronLandingPage, null) }),
                        React.createElement(Route, { path: "account/*", element: React.createElement(AccountRoutes, { accountAuthenticate: accountAuthenticate }) }),
                        React.createElement(Route, { path: "web/*", element: React.createElement(WebRoutes, { webAppAuthenticate: webAppAuthenticate }) }),
                        React.createElement(Route, { path: "*", element: React.createElement(Navigate, { to: "/web/signup", replace: true }) })),
                    React.createElement(Route, { path: "/cc" },
                        React.createElement(Route, { path: "conversation", element: React.createElement(PrivateRouteWrapper, { authenticationMethod: webAppAuthenticate, redirectPath: "/web" },
                                React.createElement(CCV2ConversationPageSwitch, null)) })),
                    React.createElement(Route, { path: CONVOV2_HOME_PAGE_ROUTE, element: React.createElement(PrivateRouteWrapper, { authenticationMethod: webAppAuthenticate, redirectPath: "/web" },
                            React.createElement(ConvoV2HomePage, null)) }),
                    React.createElement(Route, { path: CONVOV2_APP_LAUNCH_ROUTE, element: React.createElement(ConvoV2AppLaunchScreen, null) }),
                    React.createElement(Route, { path: "/web/transcript", element: React.createElement(PrivateRouteWrapper, { authenticationMethod: webAppAuthenticate, redirectPath: "/web" },
                            React.createElement(ConvoV2ConversationPageSwitch, null)) }),
                    React.createElement(Route, { path: "/convo-v1/transcript", element: React.createElement(PrivateRouteWrapper, { authenticationMethod: webAppAuthenticate, redirectPath: "/web" },
                            React.createElement(ConversationPage, null)) }),
                    React.createElement(Route, { path: '/convo-v2/transcript', element: React.createElement(PrivateRouteWrapper, { authenticationMethod: webAppAuthenticate, redirectPath: "/web" },
                            React.createElement(ConvoV2ConversationPage, null)) }))))));
};
const useStyles = makeStyles(() => ({
    container: {
        position: 'relative',
        paddingLeft: 40,
        paddingRight: 40,
        opacity: 0.98,
    },
    content: {
        paddingTop: 40,
        '& > :not(:last-child)': {
            position: 'relative',
            zIndex: Z_INDICES.accountSettingsWrapper,
            marginBottom: 16,
        },
    },
}));
const AccountSettingsWrapper = () => {
    const classes = useStyles();
    return (React.createElement("div", { className: classes.container },
        React.createElement(Navigation, null),
        React.createElement("div", { className: classes.content },
            React.createElement(Outlet, null))));
};
const OnboardingWrapper = () => {
    const navigate = useNavigate();
    useEffect(() => {
        if (window.isElectron) {
            // navigating to this route lets us have the electron layout for landing page
            // since this file is using onboarding wrapper
            navigate('web/login/desktop');
        }
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(OnboardingV2, null,
            React.createElement(Outlet, null))));
};
const WebAppWrapper = () => {
    return (React.createElement(React.Fragment, null,
        React.createElement(Default, null,
            React.createElement(Outlet, null))));
};
const RouterWrapper = () => {
    const navigate = useNavigate();
    window.navigate = navigate;
    const dispatch = useAppDispatch();
    const v1Voices = useAppSelector(selectV1TtsVoices);
    const speechLang = useAppSelector(selectSpeechLang);
    const isTextToSpeechV2 = useAppSelector(selectIsTextToSpeechV2);
    //TODO remove this once TTS 2.1 is released
    useEffect(() => {
        if (!isTextToSpeechV2) {
            dispatch(v1FindBestTTSVoice());
        }
    }, [v1Voices, speechLang, isTextToSpeechV2]);
    return (React.createElement(React.Fragment, null,
        React.createElement(LoginListener, null),
        React.createElement(Muter, null),
        React.createElement(GlobalHotkeyHandler, null),
        React.createElement(AppWrapper, null,
            React.createElement(Suspense, { fallback: React.createElement(Loading, null) },
                React.createElement(Outlet, null)))));
};
const AccountRoutes = ({ accountAuthenticate }) => {
    return (React.createElement(Routes, null,
        React.createElement(Route, { index: true, element: React.createElement(Navigate, { to: "/account/dashboard", replace: true }) }),
        React.createElement(Route, { path: "start", element: React.createElement(AccountStart, null) }),
        React.createElement(Route, { path: "login/profile", element: React.createElement(ScribeLoginProfiling, null) }),
        React.createElement(Route, { path: "payment-succeed", element: React.createElement(PrivateRouteWrapper, { authenticationMethod: accountAuthenticate, redirectPath: "/account/dashboard" },
                React.createElement(PaymentSucceed, null)) }),
        React.createElement(Route, { path: "organization/create", element: React.createElement(PrivateRouteWrapper, { authenticationMethod: accountAuthenticate, redirectPath: "/account/dashboard" },
                React.createElement(OrganizationCreate, null)) }),
        React.createElement(Route, { path: "dashboard", element: React.createElement(Dashboard, null) }),
        React.createElement(Route, { path: "license/buy", element: React.createElement(PrivateRouteWrapper, { authenticationMethod: accountAuthenticate, redirectPath: "/account/dashboard" },
                React.createElement(LicenceBuy, null)) }),
        React.createElement(Route, { path: "license/payment", element: React.createElement(PrivateRouteWrapper, { authenticationMethod: accountAuthenticate, redirectPath: "/account/dashboard" },
                React.createElement(Payment, null)) }),
        React.createElement(Route, { path: "*", element: React.createElement(Navigate, { to: "/account/dashboard", replace: true }) })));
};
const WebRoutes = ({ webAppAuthenticate }) => {
    return (React.createElement(Routes, null,
        React.createElement(Route, { index: true, element: React.createElement(Navigate, { to: "/web/signup" }) }),
        React.createElement(Route, { path: "login/desktop/success", element: React.createElement(DesktopLoginSuccess, null) }),
        React.createElement(Route, { path: "logout", element: React.createElement(LogoutComponent, null) }),
        React.createElement(Route, { path: "scribe-dashboard", element: React.createElement(PrivateRouteWrapper, { authenticationMethod: webAppAuthenticate, redirectPath: "/web" },
                React.createElement(ScribeDashboardContainer, null)) }),
        React.createElement(Route, { path: "conversations", element: React.createElement(PrivateRouteWrapper, { authenticationMethod: webAppAuthenticate, redirectPath: "/web" },
                React.createElement(ScribeConversationsContainer, null)) }),
        React.createElement(Route, { path: "account-settings/*", element: React.createElement(AccountSettingsRoutes, { webAppAuthenticate: webAppAuthenticate }) }),
        React.createElement(Route, { path: "*", element: React.createElement(Navigate, { to: "/web/signup", replace: true }) })));
};
const AccountSettingsRoutes = ({ webAppAuthenticate }) => {
    return (React.createElement(Routes, null,
        React.createElement(Route, { element: React.createElement(AccountSettingsWrapper, null) },
            React.createElement(Route, { index: true, element: React.createElement(Navigate, { to: "my-profile" }) }),
            React.createElement(Route, { path: "my-profile", element: React.createElement(PrivateRouteWrapper, { authenticationMethod: webAppAuthenticate, redirectPath: "/web" },
                    React.createElement(MyProfile, null)) }),
            React.createElement(Route, { path: "general", element: React.createElement(PrivateRouteWrapper, { authenticationMethod: webAppAuthenticate, redirectPath: "/web" },
                    React.createElement(General, null)) }),
            React.createElement(Route, { path: "boost", element: React.createElement(PrivateRouteWrapper, { authenticationMethod: webAppAuthenticate, redirectPath: "/web" },
                    React.createElement(Boost, null)) }))));
};
export default AppRoutes;
