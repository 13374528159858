var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { createNewConversation, fetchScribeSettings } from '../../actions';
import { login as accountLogin } from '../../actions/account/session';
import { getAuthInstance } from '../../firebase';
import { useV1Socket } from '../../hooks/useV1Socket';
import { selectAccountUser } from '../../selectors/account';
import { selectMicrophoneAccess, selectNeedInternalAudioAccess } from '../../selectors/audioV2';
import { selectFirebaseUser, selectIsTextToSpeechV2 } from '../../selectors/auth';
import { selectLoggedOut } from '../../selectors/combined';
import { selectShowQuickSetup } from '../../selectors/quickSetup';
import { selectElectronAppLoaded } from '../../selectors/ui';
import { selectFeatures, selectIsAdmin, selectIsProfileComplete } from '../../selectors/userProfile';
import { selectV1Token } from '../../selectors/v1Session';
import { tauriEventListen } from '../../services/desktopIntegration';
import { getIDTokenFromServiceWorkerOrFirebase, handleFirebaseUser, initiateAnonymousFirebaseLogin, initiateFirebaseLoginWithToken, loadIDToken, markFirebaseAuthTriggered, } from '../../store/slices/auth';
import { setCreateConversationWhenReady } from '../../store/slices/conversation';
import { setShowQuickSetup } from '../../store/slices/quickSetup';
import { fetchGenderPreference, fetchTtsVoices } from '../../store/slices/textToSpeech';
import { setElectronAppLoaded, setLoading } from '../../store/slices/uiState';
import { fetchUserProfile } from '../../store/slices/userProfile';
import { createV1Socket, fetchAsyncBackends } from '../../store/slices/v1Session';
import { useAppSelector } from '../../store/store';
import { getUrlParams } from '../../utils';
import AccountManager from '../../utils/classes/AccountManager';
import Branch from '../../utils/classes/Branch';
import ParseUser from '../../utils/classes/ParseUser';
import { clearSearchValue, getSearchValue } from '../../utils/http';
import * as segment from '../../utils/segment';
import { startStopwatch, stopAndTrack } from '../../utils/stopwatch';
import { CONVOV2_APP_LAUNCH_ROUTE } from '../convoV2/constants';
export const LoginListener = () => {
    // The purpose of this component is to listen to all the authentication
    // and login related events and dispatch relevant actions. This could be done
    // as part of a redux-saga or an async-thunk, but using a component lets
    // us easily listen (using useEffect) to particular fields and respond
    // appropriately. Plus it gives us a way to hook into the Firebase auth
    // process.
    var _a;
    // Current flow for regular login:
    // TODO
    // Current flow for join-by-link (anonymous, appless, qr code):
    // * The flow is triggered by setting `ava_request` in local storage.
    // * After firebase confirms that the user is logged out, isLoggedOut will be
    //   set to true. This will trigger an effect in this file to initiate
    //   anonymous login.
    // * After firebase anonymous login is complete, another effect will be
    //   triggered in this file to initiate the v1Socket creation (same effect
    //   as regular login).
    // * v1Socket creation happens in v1Session.ts. It will perform the necessary
    //   userProfile fetch, and create the socket. Afterwards it will ask the
    //   newly created joinConverstionManager whether any room should be joined.
    // * joinConversationManager will read `ava_request` from local storage and
    //   join the room if it exists.
    // * The conversation will be started and the user will be redirected to the
    //   conversation page by an effect in ScribeWelcome.
    const dispatch = useDispatch();
    const accountUser = useAppSelector(selectAccountUser);
    const avaUser = useAppSelector((state) => state.userProfile.avaUser);
    const firebaseUser = useAppSelector(selectFirebaseUser);
    const v1Token = useAppSelector(selectV1Token);
    const isLoggedOut = useAppSelector(selectLoggedOut);
    const isProfileComplete = useAppSelector(selectIsProfileComplete);
    const features = useAppSelector(selectFeatures);
    const needInternalAudioAccess = useAppSelector(selectNeedInternalAudioAccess);
    const electronAppLoaded = useAppSelector(selectElectronAppLoaded);
    const microphoneAccess = useAppSelector(selectMicrophoneAccess);
    const isAdmin = useAppSelector(selectIsAdmin);
    const showQuickSetup = useAppSelector(selectShowQuickSetup);
    const { i18n } = useTranslation();
    const [_, wsStatus] = useV1Socket();
    const location = useLocation();
    const navigate = useNavigate();
    const isTextToSpeechV2 = useAppSelector(selectIsTextToSpeechV2);
    const branch = useRef();
    const branchId = localStorage.getItem('ava_branch_id') || '';
    const urlForProfileSetup = isProfileComplete
        ? undefined
        : `/web/onboarding/profile/user-name${((_a = avaUser === null || avaUser === void 0 ? void 0 : avaUser.parse) === null || _a === void 0 ? void 0 : _a.organizationId) ? '-subscribed' : ''}`;
    const initializeBranch = () => __awaiter(void 0, void 0, void 0, function* () {
        branch.current = new Branch(branchId);
        const branchInit = branch.current.initializeBranch();
        if (branchId)
            yield branchInit;
        if (branch.current.hasData()) {
            branch.current.savePropertiesToLocalStorageIfExists();
        }
    });
    const anonymousLoginAttempted = useRef(false);
    useEffect(() => {
        if (!anonymousLoginAttempted.current &&
            isLoggedOut &&
            (v1Token || branchId || localStorage.getItem('ava_request'))) {
            anonymousLoginAttempted.current = true;
            initiateAnonymousFirebaseLogin(dispatch);
        }
    }, [isLoggedOut]);
    useEffect(() => {
        dispatch(fetchAsyncBackends());
        initializeBranch();
        const accountManager = new AccountManager();
        accountManager.setAccountDetailsInLocalStorage();
        const request = localStorage.getItem('ava_request');
        const firebaseCustomToken = getSearchValue(window, 'firebaseCustomToken');
        if (firebaseCustomToken) {
            clearSearchValue(window, 'firebaseCustomToken');
            const newUser = getSearchValue(window, 'newUser');
            if (window.isElectron && newUser) {
                dispatch(setShowQuickSetup(true));
            }
            initiateFirebaseLoginWithToken(dispatch, firebaseCustomToken);
        }
        let unlisten;
        const inner = () => __awaiter(void 0, void 0, void 0, function* () {
            if (!window.__TAURI__)
                return;
            unlisten = yield tauriEventListen('login-token', (event) => {
                const loginToken = event.payload.loginToken;
                initiateFirebaseLoginWithToken(dispatch, loginToken);
            });
        });
        inner();
        if (request)
            dispatch(setLoading(true));
        stopAndTrack('init');
        startStopwatch('initFirebaseAuth');
        const trackAndHandleFirebaseUser = (user) => {
            stopAndTrack('createAnonymousAuth');
            stopAndTrack('initFirebaseAuth', {
                // If the user is not null, but does not have one of the firebase functions,
                // it means it came from the service worker. We want to track how often this happens.
                fromServiceWorker: !!user && !user.reload,
            });
            dispatch(markFirebaseAuthTriggered());
            if (user && firebaseUser && user.uid === firebaseUser.uid && user.emailVerified && (firebaseUser === null || firebaseUser === void 0 ? void 0 : firebaseUser.emailVerified)) {
                segment.track('Firebase User Logged In', { handleFirebaseUser: false });
                return;
            }
            handleFirebaseUser(dispatch, user);
        };
        if (navigator.serviceWorker) {
            navigator.serviceWorker.ready.then((registration) => {
                var _a;
                const messageChannel = new MessageChannel();
                messageChannel.port1.onmessage = (event) => {
                    if (event.data.type === 'firebaseUser') {
                        const firebaseUser = event.data.firebaseUser;
                        // The original user object contains getIdToken function. While it seems that our app
                        // does not call it for the user stored in the store, we add it here for completeness.
                        if (firebaseUser)
                            firebaseUser.getIdToken = getIDTokenFromServiceWorkerOrFirebase;
                        trackAndHandleFirebaseUser(firebaseUser);
                    }
                };
                (_a = registration.active) === null || _a === void 0 ? void 0 : _a.postMessage({ type: 'getFirebaseUser', port: messageChannel.port2 }, [
                    messageChannel.port2,
                ]);
            });
        }
        const firebaseCancel = getAuthInstance().onAuthStateChanged(trackAndHandleFirebaseUser);
        return () => {
            firebaseCancel();
            // TODO: This will fail unpredictably if inner does not finish executing
            // before this gets unmounted.
            if (unlisten)
                unlisten();
        };
    }, []);
    useEffect(() => {
        if (!firebaseUser)
            return;
        startStopwatch('userProfile');
        dispatch(fetchUserProfile());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firebaseUser]);
    useEffect(() => {
        if (isTextToSpeechV2) {
            dispatch(fetchTtsVoices(i18n.language));
            dispatch(fetchGenderPreference());
        }
    }, [avaUser]);
    // this function is used to determine whether to start a conversation automatically after login or not.
    // in the case of electron, we want to dispatch the createNewConversation action only after the app has loaded
    const shouldStartConvoOnLogin = () => __awaiter(void 0, void 0, void 0, function* () {
        // We need to figure out whether to start a conversation instantly or not.
        if (location.pathname.includes('/account/') ||
            location.pathname.includes('conversations') ||
            location.pathname.includes('account-settings') ||
            location.pathname.includes('workspace') ||
            location.pathname.includes('download') ||
            location.pathname === CONVOV2_APP_LAUNCH_ROUTE) {
            return false;
        }
        let result = false;
        if (firebaseUser && !needInternalAudioAccess) {
            result = true;
        }
        const branchId = localStorage.getItem('ava_branch_id') || '';
        if (!branchId && window.isElectron && electronAppLoaded) {
            // Auto-start conversation on desktop app start
            // (except for one-click links -> for them the conversation will
            // auto start via another event)
            // Do not autostart on macOS if app was launched on login
            const launchedOnLogin = yield window.electronIPC.invokeCheckLaunchedOnLogin();
            result = !launchedOnLogin;
            dispatch(setElectronAppLoaded(false));
        }
        if (!window.isElectron && features.scribe) {
            // Never auto-start conversation for 'scribe' users on web
            result = false;
        }
        return result && !v1Token && !needInternalAudioAccess && microphoneAccess === 'granted' && !showQuickSetup;
    });
    useEffect(() => {
        if (!firebaseUser || !avaUser || firebaseUser.isAnonymous)
            return;
        const inner = () => __awaiter(void 0, void 0, void 0, function* () {
            const request = localStorage.getItem('ava_request');
            // If the user has never set up their hearing profile, we want to redirect them
            // to the profile creation page.
            if (!isProfileComplete && urlForProfileSetup) {
                navigate(urlForProfileSetup);
                return;
            }
            // If the user was signing in during the electron sign-in flow, we want to
            // redirect them.
            const queryParams = getUrlParams();
            if ((queryParams === null || queryParams === void 0 ? void 0 : queryParams.auth) === 'desktop') {
                // User has clicked 'Sign in with browser' in the desktop app. In this
                // case we have to redirect them back to a page where they can click a
                // button to redirect back to the desktop app.
                navigate(`/web/login/desktop/success${(queryParams === null || queryParams === void 0 ? void 0 : queryParams.tauri) ? '?tauri=true' : ''}`);
                return;
            }
            // we want to make sure we're redirecting the admin to the dashboard
            // only on login or if they are in the dashboard.
            // if the url contains a request they're trying to join a room, so we don't want to redirect them
            if (!window.isElectron &&
                isAdmin &&
                (location.pathname.includes('signup') || location.pathname.includes('login')) &&
                !request) {
                navigate('account/dashboard');
                return;
            }
            if (features.scribe && !window.isElectron && !v1Token && !branchId && !request) {
                navigate('/web/scribe-dashboard');
            }
            else {
                if (yield shouldStartConvoOnLogin()) {
                    if (wsStatus === 'online') {
                        createNewConversation()(dispatch, window.store.getState);
                    }
                    else {
                        dispatch(setCreateConversationWhenReady(true));
                    }
                }
                else {
                    // this will keep the user on the same page if they are not in a conversation
                    navigate(location.pathname);
                }
            }
        });
        inner();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [avaUser && avaUser.uid]);
    useEffect(() => {
        if (!firebaseUser || !avaUser)
            return;
        fetchScribeSettings()(dispatch, window.store.getState);
        dispatch(setLoading(true));
        const inner = () => __awaiter(void 0, void 0, void 0, function* () {
            const parseUser = new ParseUser(avaUser);
            parseUser.setParseIdToLocalStorage();
            parseUser.setAvaIdToLocalStorage();
            dispatch(createV1Socket());
            if (firebaseUser.isAnonymous) {
                // If the user is anonymous then there's two options:
                // * we treat them as logged out
                // * we send them directly to someone else's conversation.
                // That's handled in localStorage by ava_request
                const avaRequest = localStorage.getItem('ava_request');
                if (!avaRequest) {
                    dispatch(setLoading(false));
                }
                return;
            }
            const isNew = firebaseUser.metadata.lastSignInTime === firebaseUser.metadata.creationTime;
            const justSignedIn = firebaseUser.metadata.lastSignInTime
                ? Date.parse(firebaseUser.metadata.lastSignInTime) + 60 * 1000 > Date.now()
                : false;
            if (isNew) {
                parseUser.trackSignUp();
            }
            else if (justSignedIn) {
                parseUser.trackSignIn();
            }
            const redirect = getSearchValue(window, 'redirect');
            if (redirect) {
                navigate({ pathname: redirect, search: '' });
            }
        });
        inner();
    }, [avaUser && avaUser.uid]);
    useEffect(() => {
        if (!firebaseUser)
            return;
        if (!location.pathname.includes('/account/')) {
            // Only issue accountLogin action for /account paths
            return;
        }
        if (firebaseUser.email === accountUser.email)
            return;
        const inner = () => __awaiter(void 0, void 0, void 0, function* () {
            const idToken = yield dispatch(loadIDToken()).unwrap();
            dispatch(accountLogin({
                avatar: firebaseUser.photoURL || undefined,
                email: firebaseUser.email || '',
                id: firebaseUser.uid,
                name: firebaseUser.displayName || '',
            }, idToken || ''));
        });
        inner();
    }, [firebaseUser, location.pathname]);
    return React.createElement(React.Fragment, null);
};
